import React, { useState } from 'react'
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useNavigate, useOutletContext } from 'react-router-dom';

const NewWiki = () => {

  const navigate = useNavigate();
  const [entries, selectedArticle, studentID] = useOutletContext();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const createWiki = async (requestBody) => {

    const url = `https://api.sam-tsang.workers.dev/wiki/${studentID}`;
    await fetch(url, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify(requestBody)
    });

    setTitle('')
    setContent('')
    navigate('/')
  }

  const handleSubmit = () => {
    if (title && content) {
      createWiki({
        title: title,
        content: content
      });
    }
  }

  return <div style={{ height: '100%', flex: 1, padding: '2em' }}>
    <div style={{ fontSize: 24.0, marginBottom: 16.0 }}>創建文章</div>
    <div style={{ margin: '1em 0'}}>標題</div>
    <input type='text' style={{ width: '100%'}} onChange={(e) => setTitle(e.target.value)} />
    <div style={{ margin: '1em 0'}}>內容</div>
    <div style={{
      border: '1px solid black',
      width: '100%'
    }}>
      <CKEditor
        editor={ClassicEditor}
        onChange={(_, editor) => setContent((editor).getData())}
      />
    </div>
    <div 
    onClick={handleSubmit}
    style={{
      marginTop: '2em',
      fontSize: 24.0,
      cursor: 'pointer'
    }}>提交</div>
  </div>
}

export default NewWiki
