import React from 'react';

import { useOutletContext } from "react-router-dom";

const Home = () => {
  
  const [entries, selectedArticle] = useOutletContext();
 
  return <div style={{ height: '100%', flex: 1, padding: '2em 10em', overflowY: 'scroll' }}>
    {
      entries.length > 0 && entries.find(d => d.id == selectedArticle) && <div>
        <div style={{ fontSize: 48.0, fontWeight: 600 }}>{entries.find(d => d.id == selectedArticle).title}</div>
        <div style={{ marginTop: '2em', fontSize: 18.0 }} dangerouslySetInnerHTML={{ __html: entries.find(d => d.id == selectedArticle).content }} />
      </div>
    }
  </div>
}

export default Home
