
import App from './components/App'
import Home from './components/Wiki'
import NewWiki from './components/NewWiki'
import Wiki from './components/Wiki'

export default [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Wiki />,
      },
      {
        path: "/:id",
        element: <Wiki />,
      },
      {
        path: "/wiki/new",
        element: <NewWiki />,
      }
    ],
  }
]